<template>

    <div class="grid grid-flow-row gap-4">
        <div class="flex justify-center min-height-auto">
            <h1
                class="text-3xl text-center font-bold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-3xl">
                Genera tu presupuesto
            </h1>
        </div>

        <form @submit.prevent="submitForm">

            <div class="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto my-4 sm:my-10">
                <div class="grid md:grid-cols-2 gap-3">
                    <div>
                        <div class="grid space-y-3">
                            <dl class="flex flex-col sm:flex-row gap-x-3 text-sm">
                                <dt class="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                                    Nombre completo:
                                </dt>
                                <dd class="text-gray-800 dark:text-neutral-200">
                                    <spam class="inline-flex items-center gap-x-1.5">
                                        <input type="text" v-model="nombreCompleto" id="nombre"
                                            class="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            required />
                                    </spam>
                                </dd>
                            </dl>

                            <dl class="flex flex-col sm:flex-row gap-x-3 text-sm">
                                <dt class="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                                    Correo Electronico:
                                </dt>
                                <input type="email" v-model="email" id="email"
                                    class="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    required />
                            </dl>

                            <dl class="flex flex-col sm:flex-row gap-x-3 text-sm">
                                <dt class="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                                    Telefono:
                                </dt>
                                <input type="tel" v-model="telefono" id="telefono"
                                    class="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    required />
                            </dl>
                        </div>
                    </div>
                    <!-- Col -->

                    <div>
                        <div class="grid space-y-3">
                            <dl class="flex flex-col sm:flex-row gap-x-3 text-sm">
                                <dt class="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                                    Presupuesto numero:
                                </dt>
                                <dd class="font-medium text-gray-800 dark:text-neutral-200">

                                    <input type="text" v-model="codigoCotizacion" id="codigoCotizacion"
                                        class="block w-full p-0 text-gray-900 border border-white  text-xs  "
                                        readonly />
                                </dd>
                            </dl>



                            <dl class="flex flex-col sm:flex-row gap-x-3 text-sm">
                                <dt class="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                                    Fecha:
                                </dt>
                                <dd class="font-medium text-gray-800 dark:text-neutral-200">
                                    <input type="text" v-model="fecha" id="fecha"
                                        class="block w-full p-0 text-gray-900 border border-white  text-xs  "
                                        readonly />
                                </dd>
                            </dl>

                            <dl class="flex flex-col sm:flex-row gap-x-3 text-sm">
                                <dt class="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                                    Servicio:
                                </dt>
                                <dd class="font-medium text-gray-800 dark:text-neutral-200">
                                    <select v-model="servicioSeleccionado" id="servicio"
                                        class="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        @change="resetCaracteristicas">
                                        <option disabled value="">Por favor selecciona uno</option>
                                        <option v-for="servicio in servicios" :key="servicio.id" :value="servicio">
                                            {{ servicio.nombre }}
                                        </option>
                                    </select>
                                </dd>
                            </dl>


                        </div>
                    </div>

                </div>

                <div class="mt-6 border border-gray-200 p-4 rounded-lg space-y-4 dark:border-neutral-700">
                    <div class="hidden sm:grid sm:grid-cols-5">
                        <div class="sm:col-span-2 text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                            Item</div>
                        <div class="text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                        </div>
                        <div class="text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                        </div>
                        <div class="text-end text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                        </div>
                    </div>

                    <div class="hidden sm:block border-b border-gray-200 dark:border-neutral-700"></div>

                    <div class="grid grid-cols-3 sm:grid-cols-5 gap-2">
                        <div class="col-span-full sm:col-span-2">
                            <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">Item
                            </h5>
                            <div v-if="servicioSeleccionado">

                                <div v-for="caracteristica in servicioSeleccionado.caracteristicas"
                                    :key="caracteristica.nombre">
                                    <label>
                                        <input type="checkbox" v-model="caracteristica.seleccionada"
                                            @change="calcularTotal" />
                                        {{ caracteristica.nombre }} (+${{ caracteristica.costo }})
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-8 flex sm:justify-end">
                    <div class="w-full max-w-2xl sm:text-end space-y-2">
                        <!-- Grid -->
                        <div class="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">


                            <dl class="grid sm:grid-cols-5 gap-x-3 text-sm">
                                <dt class="col-span-3 text-gray-500 dark:text-neutral-500">Total:</dt>
                                <dd class="col-span-2 font-medium text-gray-800 dark:text-neutral-200">${{
                                    totalCotizacion }}</dd>
                            </dl>
                        </div>
                        <!-- End Grid -->
                    </div>

                </div>
                <div class="mb-5 pb-5 pt-6 flex justify-end items-center ">
                    <div class="inline-flex gap-x-2">
                        <router-link to="/presupuesto" class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                            >
                            
                            Volver
                        </router-link>
                        <button type="submit"
                            class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-green-400 text-white hover:bg-green-700 disabled:opacity-50 disabled:pointer-events-none">

                            <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="m9 5 7 7-7 7" />
                            </svg>

                            Enviar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            codigoCotizacion: this.generarCodigoCotizacion(),
            nombreCompleto: '',
            email: '',
            telefono: '',
            servicioSeleccionado: null,
            totalCotizacion: 0,
            fecha: this.obtenerFechaActual(),
            servicios: [
                {
                    id: 1,
                    nombre: 'Servicio 1',
                    caracteristicas: [
                        { nombre: 'Dominio', costo: 10, seleccionada: false },
                        { nombre: 'Hosting', costo: 20, seleccionada: false },
                        { nombre: 'Diseño', costo: 30, seleccionada: false },
                        { nombre: 'Asesoría', costo: 15, seleccionada: false }
                    ]
                },
                {
                    id: 2,
                    nombre: 'Servicio 2',
                    caracteristicas: [
                        { nombre: 'Desarrollo Web', costo: 50, seleccionada: false },
                        { nombre: 'SEO', costo: 25, seleccionada: false },
                        { nombre: 'Mantenimiento', costo: 40, seleccionada: false }
                    ]
                },
                {
                    id: 3,
                    nombre: 'Servicio 3',
                    caracteristicas: [
                        { nombre: 'Aplicación Móvil', costo: 100, seleccionada: false },
                        { nombre: 'Integración API', costo: 60, seleccionada: false },
                        { nombre: 'Soporte Técnico', costo: 30, seleccionada: false }
                    ]
                }
            ]
        };
    },
    methods: {
        generarCodigoCotizacion() {
            const numeroAleatorio = Math.floor(Math.random() * 10000); // Genera un número aleatorio entre 0 y 9999
            return `PRES-${numeroAleatorio}`;
        },
        obtenerFechaActual() {
            const fecha = new Date();
            const dia = String(fecha.getDate()).padStart(2, '0');
            const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Los meses son 0-indexados
            const anio = fecha.getFullYear();
            return `${dia}/${mes}/${anio}`; // Formato DD/MM/YYYY
        },
        resetCaracteristicas() {
            this.totalCotizacion = 0;
            if (this.servicioSeleccionado) {
                this.servicioSeleccionado.caracteristicas.forEach(caracteristica => {
                    caracteristica.seleccionada = false;
                });
            }
        },
        calcularTotal() {
            this.totalCotizacion = 0;
            if (this.servicioSeleccionado) {
                this.servicioSeleccionado.caracteristicas.forEach(caracteristica => {
                    if (caracteristica.seleccionada) {
                        this.totalCotizacion += caracteristica.costo;
                    }
                });
            }
        },
        submitForm() {
    const caracteristicasSeleccionadas = this.servicioSeleccionado.caracteristicas
        .filter(caracteristica => caracteristica.seleccionada)
        .map(caracteristica => caracteristica.nombre)
        .join(', ');

    const mensaje = `Hola, mi nombre es ${this.nombreCompleto}, mi email es ${this.email}, y mi teléfono es ${this.telefono}. Estoy interesado en el ${this.servicioSeleccionado.nombre} con las siguientes características: ${caracteristicasSeleccionadas}. El total de la cotización es: $${this.totalCotizacion}. Mi código de cotización es: ${this.codigoCotizacion}. Fecha: ${this.fecha}.`;

    const encodedMensaje = encodeURIComponent(mensaje);
    
    // Formato correcto para WhatsApp
    const numeroWhatsApp = this.telefono.replace(/\D/g, '');
    const enlaceWhatsApp = `https://wa.me/${numeroWhatsApp}?text=${encodedMensaje}`;

    window.open(enlaceWhatsApp, '_blank');
}
    }
};
</script>